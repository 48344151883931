import React from 'react'

import styled, { keyframes } from 'styled-components'

const topLogoDataURI = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOThweCIgaGVpZ2h0PSI5OHB4IiB2aWV3Qm94PSIwIDAgOTggOTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ4LjIgKDQ3MzI3KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5VbnRpdGxlZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJsb2dvLnRleHQuY29sb3JlZF9iYWNrdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCA2LjAwMDAwMCkiIHN0cm9rZT0iIzUzQTBGRiIgc3Ryb2tlLXdpZHRoPSIxMi42MDQ1MzciPgogICAgICAgICAgICA8cGF0aCBkPSJNNDIuNzUwMjksODUuNDQ0NDMgQzE5LjQyMjE0MzksODUuNDM3NzA5NSAwLjUxNDUwNzc5Myw2Ni41MjQ2MjcxIDAuNTE0NTA2LDQzLjE5NjQ4IEMwLjUxNDUwNDY3NCwzMS45OTE2MjQxIDQuOTY1NjE1NjksMjEuMjQ1Njg5NiAxMi44ODg2NDUyLDEzLjMyMjY1OTkgQzIwLjgxMTY3NDcsNS4zOTk2MzAyMSAzMS41NTc2MDkxLDAuOTQ4NTE4OTM5IDQyLjc2MjQ2NSwwLjk0ODUyIEM2Ni4wOTUzNjQzLDAuOTQ4NTI1NTIzIDg1LjAxMDQxMjgsMTkuODYzNTgwNyA4NS4wMTA0MSw0My4xOTY0OCIgaWQ9InBhdGg5NzAwIj48L3BhdGg+CiAgICAgICAgPC9nPgogICAgPC9nPgo8L3N2Zz4=`

const bottomLogoDataURI = `data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iOThweCIgaGVpZ2h0PSI5OHB4IiB2aWV3Qm94PSIwIDAgOTggOTgiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayI+CiAgICA8IS0tIEdlbmVyYXRvcjogU2tldGNoIDQ4LjIgKDQ3MzI3KSAtIGh0dHA6Ly93d3cuYm9oZW1pYW5jb2RpbmcuY29tL3NrZXRjaCAtLT4KICAgIDx0aXRsZT5VbnRpdGxlZDwvdGl0bGU+CiAgICA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4KICAgIDxkZWZzPjwvZGVmcz4KICAgIDxnIGlkPSJQYWdlLTEiIHN0cm9rZT0ibm9uZSIgc3Ryb2tlLXdpZHRoPSIxIiBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPgogICAgICAgIDxnIGlkPSJsb2dvLnRleHQuY29sb3JlZF9iYWNrdXAiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYuMDAwMDAwLCAtNDQuMDAwMDAwKSIgc3Ryb2tlPSIjRjE4NDcyIiBzdHJva2Utd2lkdGg9IjEyLjYwNDUzNyI+CiAgICAgICAgICAgIDxwYXRoIGQ9Ik00Mi43NzQ2NDgsNTAuOTA3MjcgQzY2LjEwMjg1OTcsNTAuOTEzOTk3MSA4NS4wMTA1MTc2LDY5LjgyNzE4NzMgODUuMDEwNDIsOTMuMTU1NCBDODUuMDEwMzg5NiwxMTYuNDg4Mjc1IDY2LjA5NTM1MTUsMTM1LjQwMzI5NCA0Mi43NjI0NzYsMTM1LjQwMzMgQzE5LjQyOTU4ODQsMTM1LjQwMzMxMSAwLjUxNDUzMTM3NiwxMTYuNDg4Mjg4IDAuNTE0NTAxLDkzLjE1NTQgQzAuNTE0NTAyMjc2LDkzLjEyNDczMzMgMC41MTQ1MzY5NDMsOTMuMDk0MDY2NiAwLjUxNDYwNSw5My4wNjM0IiBpZD0icGF0aDk3MDIiPjwvcGF0aD4KICAgICAgICA8L2c+CiAgICA8L2c+Cjwvc3ZnPg==`

const Wrapper = styled.div`
  display: inline-block;
  position: relative;

  width: ${props => props.width || '70px'};
  height: ${props => props.height || '105px'};
`

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  90% {
    transform: rotate(360deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

const RotatingIcon = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  height: 0;
  padding-top: 100%;
  font-size: 0;
  overflow: hidden;
  animation: ${rotate} 1.7s ease-in-out infinite;
  background-repeat: no-repeat;
  background-size: 100%;
`

const TopIcon = styled(RotatingIcon)`
  top: 0;
  background-image: url("${topLogoDataURI}");
`

const BottomIcon = styled(RotatingIcon)`
  bottom: 0;
  background-image: url("${bottomLogoDataURI}");
`

const Loader = props => {
  return (
    <Wrapper {...props}>
      <TopIcon />
      <BottomIcon />
    </Wrapper>
  )
}

const FullPage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`

export const FullPageLoader = ({ className, ...props }) => (
  <FullPage className={className}>
    <Loader {...props} />
  </FullPage>
)

export default Loader
